import { takeEvery, put, select } from 'redux-saga/effects'
import { RELOAD_VALUE, changeValue, putFounded } from '../../store/Search/actions'

export default function* () {
    yield takeEvery(RELOAD_VALUE, worker)
}

function* worker() {


    const lastUpdated = yield select(state => state.search.lastUpdated)


    console.log({ RELOAD_VALUE }, lastUpdated);

    if (lastUpdated !== "tracks") {
        return;
    }



    const value = yield select(state => state.search.tracks.value)



    yield put(changeValue({
        type: "tracks",
        value
    }))
}